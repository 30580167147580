<template>
  <div class="orders-table">
    <v-row
      no-gutters
      class="text-body-2 secondary--text font-weight-bold mt-5 mb-2"
    >
      <v-col cols="1" class="hidden-md-and-down">
        <strong>N°</strong>
      </v-col>
      <v-col cols="2" class="hidden-sm-and-down">
        <strong>Data ordine</strong>
      </v-col>
      <v-col cols="3" md="2">
        <strong>Stato Ordine</strong>
      </v-col>
      <v-col cols="7" md="5" lg="5">
        <strong>Consegna/Ritiro</strong>
      </v-col>
      <v-col cols="2" md="2" lg="1">
        <strong>Totale</strong>
      </v-col>
      <v-col cols="1" class="hidden-sm-and-down"> </v-col>
    </v-row>

    <v-list>
      <v-list-item
        v-for="order in orders"
        :key="order.orderId"
        v-bind:to="'/profile/orders/' + order.orderId"
      >
        <v-row no-gutters align="center">
          <v-col cols="1" class="hidden-md-and-down">
            <strong class="order-id">#{{ order.orderId }}</strong>
          </v-col>
          <v-col cols="2" class="secondary--text hidden-sm-and-down">
            <span>
              {{ $dayjs(order.addDate).format("DD/MM/YYYY - HH:mm") }}
            </span>
          </v-col>
          <v-col cols="3" md="2">
            <OrderStatusBadge v-bind:order="order" :disabled="true" />
          </v-col>

          <v-col cols="7" md="5" lg="5">
            <span class="text-capitalize d-flex flex-column">
              <span class="d-block d-lg-none">
                Ordine n.{{ order.orderId }}
              </span>
              <!-- <img
                class="address-image d-none d-lg-inline"
                :src="
                  $t(
                    'navbar.service.' +
                      order.shippingAddress.deliveryServiceId +
                      '.icon',
                    { color: 'color' }
                  )
                "
              /> -->
              <span class="secondary--text">
                {{
                  $t(
                    "navbar.address." +
                      order.shippingAddress.addressTypeId +
                      ".format",
                    order.shippingAddress
                  )
                }}
              </span>
              <span class="secondary--text">
                {{
                  $dayjs(
                    order.timeslot.date + " " + order.timeslot.beginTime,
                    "YYYY-MM-DD hh:mm:ss A"
                  ).format("D MMMM | HH:mm")
                }}
              </span>
            </span>
          </v-col>
          <v-col cols="2" md="2" lg="1">
            <OrderGrossTotal :item="order"></OrderGrossTotal>
          </v-col>
          <v-col cols="1" class="actions-order justify-end hidden-sm-and-down ">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  width="40"
                  min-width="40"
                  @click.stop.prevent="editOrder(order.orderId)"
                  class="secondary--text ml-3"
                  v-if="order.isEditable"
                >
                  <v-icon>$edit</v-icon>
                </v-btn>
              </template>
              <span>Modifica ordine</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  width="40"
                  min-width="40"
                  class="secondary--text ml-3"
                  @click.stop.prevent="deleteOrder(order.orderId)"
                  v-if="order.isDeletable"
                >
                  <v-icon>$delete</v-icon>
                </v-btn>
              </template>
              <span>Annulla ordine</span>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  large
                  v-bind="attrs"
                  v-on="on"
                  icon
                  class="main-button"
                  color="primary"
                  v-if="order.orderStatusId == 8 || order.orderStatusId == 10"
                  @click.stop.prevent="addAllToCart(order.orderId)"
                >
                  <v-icon>$cart</v-icon>
                </v-btn>
              </template>
              <span>Aggiungi tutto al carrello</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </div>
</template>
<style scoped lang="scss">
.orders-table {
  .v-list {
    background-color: transparent;
    .v-list-item {
      background-color: $white;
      border: 1px solid var(--v-grey-lighten1);
    }
  }
}
</style>
<script>
import OrderStatusBadge from "@/components/orders/OrderStatusBadge";
import OrderGrossTotal from "@/components/orders/OrderGrossTotal";
import login from "~/mixins/login";

// import OrderService from "~/service/orderService";
import { mapState, mapGetters } from "vuex";
export default {
  name: "OrdersTable",
  mixins: [login],
  props: ["orders"],
  components: {
    OrderStatusBadge,
    OrderGrossTotal
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getItemByProductId: "cart/getItemByProductId"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    timeslot() {
      return this.getItemByProductId(this.product.productId);
    },
    quantity() {
      return this.item ? this.item.quantity : 0;
    },
    canIRateOrder() {
      return this.order.orderStatusId === 8 || this.order.orderStatusId === 18;
    }
  },
  methods: {
    delivered(order) {
      return (
        order.orderStatusId == 6 ||
        order.orderStatusId == 8 ||
        order.orderStatusId == 18
      );
    },
    rateOrder(orderId) {
      this.$router.push("/order/" + orderId);
    },
    async deleteOrder(orderId) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeOrder", {
          orderId: orderId
        })
      });
      if (res) {
        this.$emit("delete", orderId);
      }
    },
    async editOrder(orderId) {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("message.confirmOrderEdit", {
          orderId: orderId
        }),
        width: 575
      });
      if (res) {
        this.$emit("editOrder", orderId);
      }
    },
    async addAllToCart(orderId) {
      if (await this.needLogin("addAllToCart")) {
        if (await this.needAddress()) {
          if (await this.needTimeslot()) {
            this.$emit("addAllToCart", orderId);
          }
        }
      }
    }
  },
  mounted() {}
};
</script>
